:root {
    --total: 300; /* total particles */
    --orb-size: 100px;
    --particle-size: 2px;
    --time: 14s; 
    --base-hue: 0; /* change for diff colors (180 is nice) */
  }
  
  html, body {
    height: 100%;
  }
  
  body {
    background: black;
    
  }
  
  .wrap {
    transform: scale(2.25); /* Adjust the scale factor as needed */

    position: relative;
    display: flex;
    padding-top: 10%;
    top: 50%;
    left: 50%;
    width: 0; 
    height: 0; 
    transform-style: preserve-3d;
    perspective: 1000px;
    animation: rotate var(--time) infinite linear; /* rotate orb */
  }
  
  @keyframes rotate {
    100% {
      transform: rotateY(360deg) rotateX(360deg);
    }
  }
  
  .c {
    position: absolute;
    width: var(--particle-size);
    height: var(--particle-size);
    border-radius: 50%;
    opacity: 0; 

  }
  
  /* Use a CSS preprocessor to generate the below for each particle */
  
  /* For example, for the first particle */
  .particle-0 {
    animation: orbit-0 var(--time) infinite;
    animation-delay: 0.01s; 
  }
  
 