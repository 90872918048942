@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;600&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  height: 100%;
  @apply overflow-x-hidden;

  @apply text-white;
}
.custom-bg {
  @apply relative w-screen min-h-screen bg-center bg-cover px-0 mx-auto overflow-hidden;
  background-image: url('./assets/cherif.jpeg');
}
.custom-bg-x {
  position: relative; /* This ensures the pseudo-element positions relative to this container */
  width: 100%; /* Adjust based on your layout needs */
  min-height: 100vh;
  overflow: hidden;
}

.custom-bg-x::before {
  opacity: 80%  ;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('./assets/bg.webp') center/cover no-repeat; /* Adjust your image path */
  filter: blur(16px);
  z-index: -1; /* Keep this behind the content */
}

.custom-bg-x > * {
  position: relative; /* This ensures content is above the pseudo-element */
  z-index: 1; /* Explicitly higher than the pseudo-element */
}
.award-bg {
  background-image:url('./assets/award-leavess.webp');
  background-size: 100%;
}
.award-bg-xs {
  background-image:url('./assets/award-leavess.webp');
  background-size: 110%;
}
@layer components {
  .max-container {
    max-width: 1440px;
    margin: 0 auto;
  }

}
@layer utilities {
  .padding {
    @apply sm:px-16 px-8 sm:py-16 py-12;
  }

  .padding-x {
    @apply sm:px-16 px-8;
  }

  .padding-y {
    @apply sm:py-24 py-12;
  }

  .padding-l {
    @apply sm:pl-16 pl-8;
  }

  .padding-r {
    @apply sm:pr-16 pr-8;
  }

  .padding-t {
    @apply sm:pt-24 pt-12;
  }

  .padding-b {
    @apply sm:pb-24 pb-12;
  }

}
#particles canvas {
  z-index: -1;
  height: 500px;
  width: 100%;
  position: relative;
}
@font-face {
  font-family: 'BadScript';
  src: local('BadScript'), url(./fonts/BadScript-Regular.ttf) format('ttf');
}
