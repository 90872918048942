@keyframes slideUpFadeIn {
  from {
    opacity: 0;
    transform: translateY(150px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-up-fade-in {
  animation: slideUpFadeIn 0.5s ease-out forwards;
}
